import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Home.css";
import HomeHeader from "./Header/HomeHeader";
import Banner from "./Sections/Banner";
import AfterBanner from "./Sections/AfterBanner";
import Overview from "./Sections/Overview";
import Whynoor from "./Sections/Whynoor";
import Purpose from "./Sections/Purpose";
import Name from "./Sections/Name";
import Footer from "./Footer/Footer";

function Home() {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const languageSwitcherHandler = () => {
    i18n.changeLanguage(lang === "ar" ? "en" : "ar", () => {
      setLang(i18n.language);
    });
  };

  useEffect(() => {
    const header = document.querySelector("header");
    const sticky = header.offsetTop;

    function stickyheader() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }

    if (header) {
      window.onscroll = function onScroll() {
        stickyheader();
      };
    }
  }, []);
  return (
    <div className="home-wrapp">
      <HomeHeader langSwitch={languageSwitcherHandler} />
      <main className="home">
        <Banner />
        <AfterBanner />
        <Overview />
        <Whynoor />
        <Purpose />
        <Name lang={lang} />
        <Footer />
      </main>
    </div>
  );
}

export default Home;
