import { Col, Row, Spinner } from "react-bootstrap";
import { Button } from "../Common/Button";
import { ReactComponent as GenerateIcon } from "../Icons/icon-generate.svg";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Temperature } from "./Temperature";

export function GenerateOptions({ onClick }) {
  const { t } = useTranslation();
  const [temperature, setTemperature] = useState(1);
  const [loading, setLoading] = useState(false);
  return (
    <Row className={"align-items-center justify-content-between gx-3"}>
      <Col>
        <Button
          onClick={() => {
            setLoading(true);
            onClick(temperature).finally(() => setLoading(false));
          }}
          disabled={loading}
          icon={
            loading ? (
              <Spinner animation={"border"} size={"sm"} role="status" />
            ) : (
              <GenerateIcon width={"16px"} />
            )
          }
        >
          {t("articleGen.options.generate")}
        </Button>
      </Col>
      <Temperature onChange={setTemperature} />
    </Row>
  );
}
