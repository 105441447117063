import React from "react";
import "./Button.css";

export function Button({ children, icon, onClick, disabled, className }) {
  return (
    <button
      type={"button"}
      aria-label={"submit"}
      className={`btn rounded d-flex flex-row align-items-center text-center green glow ${
        className || ""
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      <label>{children}</label>
      <div className="icon-btn">{icon}</div>
    </button>
  );
}
