import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import "./Chat.css";
import { useTranslation } from "react-i18next";
import ChatContainer from "./ChatContainer";
import MessageInput from "./MessageInput";
import { chat } from "../../../api/Api";

let controller;

function Chat({ setAppId, setIsResetFeedback }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chatMsgs, setChatMsgs] = useState([]);

  useEffect(() => {
    let msgTimout;
    const fireInitMessages = () => {
      const initMsg = [
        {
          text: t("chatbot.initial_msg_1"),
          sender: "noor-bot",
          isFirst: true,
          id: "m1"
        }
      ];
      initMsg.forEach((msg, i) => {
        msgTimout = setTimeout(() => {
          setIsLoading(false);
          setChatMsgs((prev) => [...prev, msg]);
        }, 1000 * (i + 1));
      });
    };

    fireInitMessages();
    return () => {
      clearTimeout(msgTimout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendMsgHandler = async (enteredText) => {
    let last4;
    await setChatMsgs((prevChats) => {
      const updatedChat = [...prevChats];
      updatedChat.push({
        text: enteredText,
        sender: "user",
        isFirst: prevChats[prevChats.length - 1].sender !== "user",
        id: Math.random().toString()
      });
      last4 = updatedChat
        .slice(-4)
        .map((obj) => ({ sender: obj.sender, text: obj.text }));
      return updatedChat;
    });

    if (controller) {
      controller.abort();
    }

    controller = new AbortController();
    setIsLoading(true);
    setError(null);
    setIsResetFeedback(true);

    chat(last4)
      .then((data) => {
        setChatMsgs((prevChats) => {
          const updatedChat = [...prevChats];
          updatedChat.push({
            text: data.text,
            sender: "noor-bot",
            isFirst: prevChats[prevChats.length - 1].sender !== "noor-bot",
            id: Math.random().toString()
          });
          return updatedChat;
        });
        setAppId(data.id);
      })
      .catch((errors) => {
        if (!axios.isCancel(errors)) setError(errors.message);
      })
      .finally(() => {
        setIsLoading(false);
        setIsResetFeedback(false);
      });
  };

  return (
    <div className="col-md-6 chat mx-3 mx-md-auto flex-grow-1">
      <p className="app-desc">{t("chatbot.description")}</p>
      <ChatContainer items={chatMsgs} isLoading={isLoading} />
      {error && (
        <div
          className="d-flex w-100 alert alert-danger text-start"
          dir="ltr"
          role="alert"
        >
          {error}
        </div>
      )}
      <MessageInput onSendMsg={sendMsgHandler} />
    </div>
  );
}

Chat.propTypes = {
  setAppId: PropTypes.func.isRequired,
  setIsResetFeedback: PropTypes.func.isRequired
};

export default Chat;
