import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../Home/Home.css";
import "./Pages.css";
import HomeHeader from "../Home/Header/HomeHeader";
import Footer from "../Home/Footer/Footer";
import Terms from "./Policies/Terms";
import Privacy from "./Policies/Privacy";
import Cookie from "./Policies/Cookie";
import News from "./News";

function Pages() {
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const languageSwitcherHandler = () => {
    i18n.changeLanguage(lang === "ar" ? "en" : "ar", () => {
      setLang(i18n.language);
    });
  };

  return (
    <div className="home-wrapp pages">
      <HomeHeader langSwitch={languageSwitcherHandler} />
      <main className="home">
        {pathname === "/page/terms" && <Terms />}
        {pathname === "/page/privacy" && <Privacy />}
        {pathname === "/page/cookie" && <Cookie />}
        {pathname === "/page/news" && <News />}
        <Footer />
      </main>
    </div>
  );
}

export default Pages;
