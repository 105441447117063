import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowDown } from "../Icons/arrow-down.svg";
import animatedCircle from "../Icons/m-b-animate-1.gif";

function Banner() {
  const { t } = useTranslation();

  const playgroundButton = useMemo(() => {
    const to = "/"; // "summarize"
    return (
      <Link className="btn btn-green" to={to}>
        <span className="d-block text text-white">
          {t("landing.banner.button")}
        </span>
        <span className="d-block icon" />
      </Link>
    );
  }, [t]);

  return (
    <section className="main-banner hasBg">
      <div className="container h-100 px-4 px-xl-0">
        <div className="row h-100">
          <div className="col-md-12 h-100">
            <div className="content-text row mx-0 justify-content-center h-100">
              <div className="col-md-12 p-0 mb-24 title-content mt-auto d-flex align-items-start justify-content-between">
                <span className="title d-block  fc-white">
                  {t("landing.banner.title")}
                </span>
                <div className="lottiefile">
                  <img
                    src={animatedCircle}
                    className="lootie-animate w-100"
                    alt="Noor"
                  />
                </div>
              </div>
              <div className="noor-wrap mt-auto col-md-12 p-0">
                <div className="noor-logo mb-md-5 mb-4">
                  <img src="./assets/images/noor-logo.svg" alt="" />
                </div>
                <div className="noor-desc mb-md-5 mb-4 fc-white">
                  <span className="d-block subtitle">
                    {t("landing.banner.subtitle")}
                  </span>
                </div>
                {playgroundButton}
              </div>
              <HashLink className="scroll-down" to="#intro">
                <ArrowDown />
              </HashLink>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-image">
        <img src="./assets/images/background-banner.svg" alt="" />
      </div>
    </section>
  );
}

export default Banner;
