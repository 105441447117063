import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ReactComponent as SendIcon } from "../Icons/send-icon-rtl.svg";

function MessageInput({ onSendMsg }) {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const messageChangeHandler = (event) => {
    setMessage(event.target.value);
  };
  const sendHandler = () => {
    //  event.preventDefault();
    if (message.trim().length === 0) {
      return;
    }
    onSendMsg(message);
    setMessage("");
  };

  const enterPressed = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      // 13 is the enter keycode
      sendHandler();
    }
  };
  return (
    <div className="msg-container">
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control msgbox"
          placeholder={t("chatbot.input.placeholder")}
          aria-label="Type your message here"
          aria-describedby="basic-addon2"
          value={message}
          onChange={messageChangeHandler}
          onKeyUp={enterPressed}
        />
        <div className="input-group-append">
          <button className="btn" onClick={sendHandler} type="button">
            <SendIcon />
          </button>
        </div>
      </div>
    </div>
  );
}
MessageInput.propTypes = {
  onSendMsg: PropTypes.func.isRequired
};
export default MessageInput;
