import React, { createContext, useState } from "react";
import "../ArticleGenerator/ArticleGenerator.css";
import { Topic } from "./Topic";
import { KeyPoints } from "./KeyPoints";
import { Compilation } from "./Compilation";

export const ArticleGeneratorContext = createContext({
  topic: "",
  setTopic: (topic) => {},
  title: ""
});

function ArticleGenerator() {
  const [topic, setTopic] = useState("");
  const [title, setTitle] = useState("");
  const [sections, setSections] = useState([]);

  return (
    <div className={"w-100 d-flex align-items-center flex-column"}>
      <div className={"flex-column article-generator w-100"}>
        <ArticleGeneratorContext.Provider value={{ topic, setTopic, title }}>
          <Topic
            onSelect={(selected) => {
              setTitle(selected);
            }}
          />
          <KeyPoints onSectionsChange={setSections} />
          <Compilation sections={sections} />
        </ArticleGeneratorContext.Provider>
      </div>
    </div>
  );
}

export default ArticleGenerator;
