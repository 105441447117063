import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

function Typewriter({ text, period }) {
  const [typedText, setTypedText] = useState();

  useEffect(() => {
    const toRotate = JSON.parse(text);
    let loopNum = 0;
    const per = parseInt(period, 10) || 2000;
    let txt = "";
    let isDeleting = false;
    let tickerTimout;
    tick();

    function tick() {
      const i = loopNum % toRotate.length;
      const fullTxt = toRotate[i];
      if (isDeleting) {
        txt = fullTxt.substring(0, txt.length - 1);
      } else {
        txt = fullTxt.substring(0, txt.length + 1);
      }
      setTypedText(txt);

      let delta = 200 - Math.random() * 100;

      if (isDeleting) {
        delta /= 2;
      }

      if (!isDeleting && txt === fullTxt) {
        delta = per;
        isDeleting = true;
      } else if (isDeleting && txt === "") {
        isDeleting = false;
        loopNum += 1;
        delta = 500;
      }

      tickerTimout = setTimeout(() => {
        tick();
      }, delta);
    }

    return () => {
      clearTimeout(tickerTimout);
    };
  }, [text, period]);

  return (
    <div className="h2 typewrite d-block revealme fw-bold  h2">
      <span className="wrap">{typedText}</span>
    </div>
  );
}
Typewriter.propTypes = {
  text: PropTypes.string.isRequired,
  period: PropTypes.number.isRequired
};
export default Typewriter;
