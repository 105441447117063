import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { feedback } from "../../../api/Api";

function Feedback({ appId, appName, isResetFeedback }) {
  const { t } = useTranslation();
  const [feedbackMessage, setFeedbackMessage] = useState();
  const [feedbackStatus, setFeedbackStatus] = useState(0);
  const [popupStatus, setPopupStatus] = useState(false);
  const [likeButton, setLikeButton] = useState(false);
  const [dislikeButton, setdislikeButton] = useState(false);

  const textChangeHandler = (event) => {
    setFeedbackMessage(event.target.value);
  };

  useEffect(() => {
    if (isResetFeedback === true) {
      setFeedbackStatus(0);
      setLikeButton(false);
      setdislikeButton(false);
      setFeedbackMessage("");
      setPopupStatus(false);
    } else if (isResetFeedback === false) {
      setFeedbackStatus(1);
    }
  }, [isResetFeedback]);

  function hideFeedback() {
    setFeedbackStatus(2);
  }

  function showFeedback() {
    setFeedbackStatus(1);
  }

  function hidePopup() {
    setPopupStatus(false);
  }

  function togglePopup() {
    if (!dislikeButton) {
      setLikeButton(false);
      setdislikeButton(true);
      setPopupStatus(true);
    } else {
      setdislikeButton(false);
      setPopupStatus(false);
    }
  }

  async function sendFeedback(value) {
    if (value === 10) {
      setLikeButton(!likeButton);
      setdislikeButton(false);
    } else {
      setLikeButton(false);
    }

    setPopupStatus(false);
    if (
      appId &&
      ((value === 10 && !likeButton) || (value === 0 && dislikeButton))
    ) {
      await feedback(appId, value, appName, feedbackMessage);
    }
  }

  return (
    <div className={feedbackStatus === 0 ? "hide-feedback" : ""}>
      <div
        className={
          feedbackStatus === 2
            ? "feedback_pop_wrap feedback_hide_pop"
            : "feedback_pop_wrap"
        }
      >
        <div
          className={
            popupStatus ? "feedback-form-wrap show-form" : "feedback-form-wrap"
          }
        >
          <div
            className="close_btn_form"
            onClick={hidePopup}
            role="button"
            aria-hidden="true"
          >
            <img alt="Noor" src="./assets/images/pop-close-btn-gray.svg" />
          </div>
          <div className="feedback-form-desc">
            <h5>{t("feedback.form_title")}</h5>
            <p>{t("feedback.form_desc")}</p>
          </div>
          <div className="feedack-form-textarea">
            <textarea value={feedbackMessage} onChange={textChangeHandler} />
          </div>
          <div className="feedack-form-btn">
            <button type="button" onClick={() => sendFeedback(0)}>
              {t("feedback.form_btn_text")}
            </button>
          </div>
          <div className="form-triangle"></div>
        </div>
        <div className="close_btn" aria-hidden="true" onClick={hideFeedback}>
          <img alt="Noor" src="./assets/images/pop-close-btn.svg" />
        </div>
        <div className="feedback_pop_text">
          <span>
            <strong>{t("feedback.text")}</strong>
          </span>
          <span>{t("feedback.sub_text")}</span>
        </div>
        <div className="feedback_pop_rate">
          <span
            aria-hidden="true"
            className={likeButton ? "fb_like active" : "fb_like"}
            onClick={() => sendFeedback(10)}
          >
            <img
              className="ic-like-green ic-green"
              alt="Noor"
              src="./assets/images/ic-like.svg"
            />
            <img
              className="ic-like-white ic-white"
              alt="Noor"
              src="./assets/images/ic-like-white.svg"
            />
          </span>
          <span
            aria-hidden="true"
            className={dislikeButton ? "fb_dislike active" : "fb_dislike"}
            onClick={togglePopup}
          >
            <img
              className="ic-dislike-green ic-green"
              alt="Noor"
              src="./assets/images/ic-dislike.svg"
            />
            <img
              className="ic-dislike-white ic-white"
              alt="Noor"
              src="./assets/images/ic-dislike-white.svg"
            />
          </span>
        </div>
      </div>
      <div
        className={
          feedbackStatus === 2 ? "info-feedback" : "info-feedack d-none"
        }
        aria-hidden="true"
        onClick={showFeedback}
      >
        <img alt="Noor" src="./assets/images/ic-feedback.svg" />
      </div>
    </div>
  );
}

Feedback.propTypes = {
  appId: PropTypes.string,
  appName: PropTypes.string,
  isResetFeedback: PropTypes.bool.isRequired
};

export default Feedback;
