import axios from "axios";

const BASE_URL = "https://noor.tii.ae/api/v1/";

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

export function callApi(endpoint, data) {
  return api.post(endpoint, data).then((res) => res.data);
}

export function feedback(id, value, appName, message) {
  return callApi("feedback", { id, value, app_name: appName, message });
}

export function chat(chat) {
  return callApi("chatbot", { chat });
}

export function summarize(text) {
  return callApi("summarize", { text });
}

export function narrate(text) {
  return callApi("narrate", { text });
}

export function quizzer(question, options) {
  return callApi("questions", { question, options });
}

export function articleTitle(topic, temperature) {
  return callApi("articleTitle", { temperature, topic }).then(
    (data) => data.results
  );
}

export function articleKeyPoints(topic, title, temperature) {
  return callApi("articleKeyPoints", { topic, title, temperature }).then(
    (data) => data.results
  );
}

export function articleKeyPointArgument(topic, title, keypoint, temperature) {
  return callApi("articleKeyPointArguments", {
    topic,
    title,
    keypoint,
    temperature
  }).then((data) => data.results);
}
