import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./Quizzer.css";
import QuestionBox from "./Question/QuestionBox";
import OptionsBox from "./Options/OptionsBox";
import AnswerBox from "./AnswerBox";
import QuizButton from "./QuizButton";
import { quizzer } from "../../../api/Api";

function Quizzer({ setAppId, setIsResetFeedback }) {
  const { t } = useTranslation();
  const [question, setQuestion] = useState("");
  const options = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [isValidOptions, setIsValidOptions] = useState(true);
  const [isValidQuestion, setIsValidQuestion] = useState(true);
  const [answer, setAnswer] = useState(t("quizzer.answer.placeholder"));
  const [isLoading, setIsLoading] = useState(false);
  let values = [];

  const handleQuestionChange = (value) => {
    setQuestion(value);
    if (question.trim().length > 0) {
      setIsValidQuestion(true);
    }
  };

  const QuizBtnHandler = async () => {
    setIsValidOptions(true);
    setIsValidQuestion(true);

    values = options
      .filter((option) => option.current.value.trim().length !== 0)
      .map((element) => element.current.value);

    if (question.trim().length === 0) {
      setIsValidQuestion(false);
      return;
    }
    if (values.length < 0) {
      setIsValidOptions(false);

      return;
    }
    setIsLoading(true);
    setIsResetFeedback(true);

    quizzer(question, values)
      .then((data) => {
        setAnswer(data.text);
        setAppId(data.id);
      })
      .catch((error) => {
        setAnswer(error.message);
      })
      .finally(() => {
        setIsResetFeedback(false);
        setIsLoading(false);
      });
  };

  return (
    <div className="container quizzer-container mx-auto">
      <p className="app-desc">{t("quizzer.description")}</p>
      <QuestionBox changeHandler={handleQuestionChange} />
      {!isValidQuestion && (
        <div className="invalid-feedback d-block">
          {t("quizzer.question.error")}
        </div>
      )}
      <OptionsBox
        ref={{
          ref1: options[0],
          ref2: options[1],
          ref3: options[2],
          ref4: options[3]
        }}
      />
      {!isValidOptions && (
        <div className="invalid-feedback d-block">
          {t("quizzer.options.error")}
        </div>
      )}
      <QuizButton onQuizClick={QuizBtnHandler} />

      <AnswerBox isLoading={isLoading} answer={answer} />
    </div>
  );
}

Quizzer.propTypes = {
  setAppId: PropTypes.func.isRequired,
  setIsResetFeedback: PropTypes.func.isRequired
};

export default Quizzer;
