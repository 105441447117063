import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

function QuizButton({ onQuizClick }) {
  const { t } = useTranslation();
  const QuizClickHandler = (event) => {
    onQuizClick(event);
  };
  return (
    <div className="form-input form-action mt-4 text-center">
      <button
        className="btn btn-green quiz-btn"
        onClick={QuizClickHandler}
        type="button"
      >
        <div className="text text-white">{t("quizzer.button")}</div>
      </button>
    </div>
  );
}

QuizButton.propTypes = {
  onQuizClick: PropTypes.func.isRequired
};
export default QuizButton;
