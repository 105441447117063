import React from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie-player";
import lottieJsonleft from "../Shapes/left-4.json";

function Whynoor() {
  const { t } = useTranslation();
  return (
    <section className="proposal" id="whynoor">
      <div className="lottie-wrap">
        <div className="lottiefile">
          <Lottie
            className="lootie-animate animOne"
            loop
            animationData={lottieJsonleft}
            play
            style={{ width: "100%" }}
          />
        </div>
      </div>

      <div className="px-4 px-xl-0 container">
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-8">
            <div className="section-title mb-5 text-center">
              <span className="h2 d-block  fw-bold revealme">
                {t("landing.whynoor.title")}
              </span>
            </div>
          </div>

          <div className="col-md-10">
            <div className="section-content text-center revealme">
              <span className="h5 d-block mb-4 ">
                {t("landing.whynoor.para_1")}
              </span>

              <span className="h5 d-block mb-4 ">
                {t("landing.whynoor.para_2")}
              </span>

              <span className="h5 d-block mb-4 ">
                {t("landing.whynoor.para_3")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Whynoor;
