import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as NoorLogoCircle } from "../Icons/noor-logo-circle.svg";
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import DropdownMenu from "react-bootstrap/DropdownMenu";

export default function Header() {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const menuItems = useMemo(
    () => [
      { title: t("chatbot.title"), path: "chatbot" },
      {
        title: t("summarizer.title"),
        path: "summarize"
      },
      { title: t("quizzer.title"), path: "quizzer" },
      {
        title: t("narrator.title"),
        path: "narrator"
      },
      { title: t("articleGen.title"), path: "articleGenerator" }
    ],
    [t]
  );

  const title =
    menuItems.find((item) => {
      return `/${item.path}` === pathname;
    }).title || "404 What are you looking for? 😜";

  return (
    <header className="App-header">
      <div className="navbar navbar-expand-lg justify-content-center">
        <div className="container justify-content-center position-relative m-1">
          <div className="d-flex align-items-center position-absolute end-0">
            <div className="nav-brand d-inline-block">
              <Link className="App-link" to="/">
                <NoorLogoCircle />
              </Link>
            </div>
          </div>
          <div className="nav-name d-flex align-items-center">
            <Dropdown>
              <DropdownToggle className={"d-flex flex-row gap-2"}>
                {title}
              </DropdownToggle>
              <DropdownMenu>
                {menuItems.map((item) => {
                  return (
                    <Dropdown.Item key={item.path} href={item.path}>
                      {item.title}
                    </Dropdown.Item>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
    </header>
  );
}
