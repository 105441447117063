import { useTranslation } from "react-i18next";
import { ReactComponent as Chevron } from "../Icons/arrow-down.svg";
import { Form } from "react-bootstrap";

export function SelectButton({ onClick }) {
  const { t } = useTranslation();
  return (
    <button
      onClick={(event) => {
        event.preventDefault();
        onClick();
      }}
      className={"d-flex flex-column p-0 select-option cur align-items-center"}
    >
      <Form.Text className={"m-0"}>{t("articleGen.select.label")}</Form.Text>
      <Chevron />
    </button>
  );
}
