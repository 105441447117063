import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { OptionsCarousel } from "./OptionsCarousel";
import { articleTitle } from "../../../api/Api";
import { ArticleGeneratorContext } from "./ArticleGenerator";

export function Topic({ onSelect }) {
  const { t } = useTranslation();
  const { topic, setTopic } = useContext(ArticleGeneratorContext);

  const onGenerate = useCallback(
    (temperature) => {
      return articleTitle(topic, temperature);
    },
    [topic]
  );

  const flexWithGap = "d-flex flex-column gap-3";
  return (
    <Form className={`${flexWithGap} m-2`}>
      <Form.Label className={"h2"}>{t("articleGen.topic.title")}</Form.Label>
      <Form.Group className={flexWithGap}>
        <Form.Group>
          <Form.Text>{t("articleGen.topic.tooltip")}</Form.Text>
          <Form.Control
            type={"text"}
            placeholder={t("articleGen.topic.placeholder")}
            onChange={(event) => setTopic(event.target.value)}
            value={topic}
          />
        </Form.Group>
      </Form.Group>
      <OptionsCarousel
        onGenerate={onGenerate}
        onSelect={onSelect}
        lineHeight={"auto"}
      />
    </Form>
  );
}
