import Carousel from "react-bootstrap/Carousel";
import { useCallback, useState } from "react";
import "./OptionsCarousel.css";
import { Collapse, Form, Row } from "react-bootstrap";
import { SelectButton } from "./SelectButton";
import { GenerateOptions } from "./GenerateOptions";

export function OptionsCarousel({ onGenerate, onSelect, lineHeight }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [options, setOptions] = useState([]);
  const generateOptions = useCallback(
    (temperature) => {
      return onGenerate(temperature).then(setOptions);
    },
    [onGenerate]
  );

  return (
    <Form.Group className={"d-flex flex-column gap-3"}>
      <GenerateOptions onClick={generateOptions} />
      <Collapse in={Boolean(options.length)}>
        <Row className={"d-flex flex-column gap-3 gx-3"}>
          <Carousel
            className={"p-0"}
            onSelect={setActiveIndex}
            interval={null}
            controls={false}
          >
            {options.map((option) => (
              <Carousel.Item
                key={`${option}`}
                className={"p-3 mb-3 text-white option overflow-auto"}
                style={{
                  "--line-height": lineHeight || "12em"
                }}
              >
                <p>{option}</p>
              </Carousel.Item>
            ))}
          </Carousel>
          <Form.Group className={"d-flex justify-content-center"}>
            <SelectButton onClick={() => onSelect(options[activeIndex])} />
          </Form.Group>
        </Row>
      </Collapse>
    </Form.Group>
  );
}
