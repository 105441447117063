import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Summarize.css";
import { useTranslation } from "react-i18next";
import { ReactComponent as GenerateIcon } from "../Icons/icon-generate.svg";
import { summarize } from "../../../api/Api";

function Summarize({ setAppId, setIsResetFeedback }) {
  const { t } = useTranslation();
  const [summarizedText, setSummarizedText] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [isValid, setIsValid] = useState(true);
  const [enteredText, setEnteredText] = useState(t("summarizer.input.initial"));
  const textRegex = /[0-9\u0660-\u0669`~!@#$%^»«&*()_|+\-=?;:'",.<>{}[\]\\/]/gi;
  const MIN_INPUT_LENGTH = 400;

  const textChangeHandler = (event) => {
    setIsValid(true);
    setEnteredText(event.target.value);
  };

  async function getSummaryHandler() {
    setIsLoading(true);
    setError(null);
    setIsResetFeedback(true);

    if (enteredText.trim().replace(textRegex, "").length < MIN_INPUT_LENGTH) {
      setIsValid(false);
      setIsLoading(false);
      return;
    }

    summarize(enteredText)
      .then((data) => {
        setSummarizedText(data.text);
        setAppId(data.id);
      })
      .catch((errors) => {
        setError(errors.message);
      })
      .finally(() => {
        setIsResetFeedback(false);
        setIsLoading(false);
      });
  }

  let summaryContent = <p>{t("summarizer.generatedtext.placeholder")}</p>;
  if (summarizedText != null) {
    summaryContent = <p>{summarizedText}</p>;
  }
  if (error) {
    summaryContent = (
      <p>
        {error} {t("app.generalerror")}
      </p>
    );
  }
  if (isLoading) {
    summaryContent = <p>{t("app.loading")}</p>;
  }

  return (
    <div className="summarize-wrap">
      <div className="input-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="app-desc">{t("summarizer.description")}</p>
              <div className="form-input">
                <small className="text-muted">
                  {t("summarizer.input.hint")}
                </small>
                <textarea
                  className={`form-control ${!isValid ? "is-invalid" : ""}`}
                  value={enteredText}
                  onChange={textChangeHandler}
                  placeholder={t("summarizer.input.placeholder")}
                />
                {!isValid && (
                  <div className="invalid-feedback w-50 d-inline">
                    {t("summarizer.input.error")}
                  </div>
                )}
                <small className="text-muted float-start">
                  {enteredText.trim().replace(textRegex, "").length}
                  {t("summarizer.letters")}
                </small>
              </div>
              <div className="form-input form-action my-5">
                <button
                  type="button"
                  className="btn btn-green"
                  aria-label="submit"
                  onClick={getSummaryHandler}
                  disabled={isLoading}
                >
                  <div className="text">{t("summarizer.button")}</div>
                  <div className="icon-btn">
                    <GenerateIcon />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-generate">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10">
              <div className="generate-content">
                <h6>{t("summarizer.generatedtext.title")}</h6>
                {summaryContent}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Summarize.propTypes = {
  setAppId: PropTypes.func.isRequired,
  setIsResetFeedback: PropTypes.func.isRequired
};

export default Summarize;
