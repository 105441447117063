import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as UserAvatar } from "../Icons/user.svg";

function UserBubble({ isFirst, text }) {
  return (
    <div className="user">
      <div className="bubble" style={!isFirst ? { marginLeft: "32px" } : null}>
        {text}
      </div>
      {isFirst && (
        <div className="img me-1">
          <UserAvatar />
        </div>
      )}
    </div>
  );
}

UserBubble.propTypes = {
  isFirst: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired
};

export default UserBubble;
