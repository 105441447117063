import React from "react";
import PropTypes from "prop-types";
import { HashLink } from "react-router-hash-link";
import { Trans } from "react-i18next";

function List({ sub }) {
  return (
    <div>
      {sub.list && (
        <ul>
          {sub.list.map((item) => (
            <li>
              <Trans
                key={item}
                components={{
                  terms13: <HashLink to="#term13" />
                }}
              >
                {item.text}
              </Trans>
              <List sub={item} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

List.propTypes = {
  sub: PropTypes.array.isRequired
};

export default List;
