import React, { useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { useTranslation, Trans } from "react-i18next";
import List from "../List";

function Terms() {
  const { t } = useTranslation();
  const terms = t("terms_conditions.terms", { returnObjects: true });

  useEffect(() => {
    const header = document.querySelector("header");
    const sticky = header.offsetTop;
    function stickyheader() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
    if (header) {
      window.onscroll = function onscroll() {
        stickyheader();
      };
    }
  }, []);

  return (
    <section className="policies">
      <div className="px-4 px-xl-0 mt-5 container">
        <div className="row justify-content-center">
          <div className="col-md-11 col-lg-12">
            <div className="section-title mb-5 text-center">
              <span className="h1 d-block  fw-bold revealme">
                {t("terms_conditions.title")}
              </span>
              <span className="h5 d-block  revealme">
                {t("terms_conditions.subtitle")}
              </span>
            </div>
          </div>
          <div className="col-md-11 col-lg-12">
            <div className="section mb-5">
              <span className="h3 d-block  fw-bold revealme">
                {t("terms_conditions.subtitle2")}
              </span>
              <span className="h5 d-block  revealme">
                {t("terms_conditions.subtitle3")}
              </span>
              <span className="h5 d-block  revealme">
                {t("terms_conditions.subtitle4")}
              </span>
              <ul>
                {terms.map((term, index) => (
                  <li>
                    <HashLink to={`#term'${index}`}>{term.text}</HashLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-md-11 col-lg-12 mt-3">
            {terms.map((term, index) => (
              <div className="mt-3" id={`term'${index}`}>
                <span className="h3 d-block  fw-bold revealme">
                  {term.text}
                </span>
                {term.subtext.map((sub) => (
                  <div>
                    <Trans
                      key={terms}
                      components={{
                        terms8: <HashLink to="#term8" />,
                        info: (
                          <a href="mailto:info@mbzirc.com">info@mbzirc.com</a>
                        )
                      }}
                    >
                      <p>{sub.para}</p>
                    </Trans>
                    <List sub={sub} />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Terms;
