import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie-player";
import lottieJson1 from "./graphic.json";

function News() {
  const { t } = useTranslation();
  const newslists = t("news_list.newsitems", { returnObjects: true });

  useEffect(() => {
    const header = document.querySelector("header");
    const sticky = header.offsetTop;
    header.classList.add("news-header");
    function stickyheader() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
    if (header) {
      window.onscroll = function onscroll() {
        stickyheader();
      };
    }
  }, []);

  return (
    <div className="inner-wrap">
      <div className="inner-content">
        <div className="lottie-wrap">
          <div className="lottiefile">
            <Lottie
              className="lootie-animate animOne"
              loop
              animationData={lottieJson1}
              play
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="px-4 px-xl-0 mt-5 container news-container">
          <div className="inner-title-sec text-center">
            <h2>{t("news_list.title")}</h2>
          </div>
          <div className="inner-news-block">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-8">
                <div className="image-section">
                  <img
                    src="./assets/images/news_image01.jpg"
                    alt="News"
                    className="w-100"
                  />
                </div>
              </div>
              <div className="ol-12 col-md-12 col-xl-4">
                <div className="inner-contetn-sec">
                  <div className="content-top">
                    <h3>{t("news_list.subtitle")}</h3>
                    <p>{t("news_list.subtext")}</p>
                  </div>
                  <div className="content-bottom">
                    <div className="date">{t("news_list.newsdate")}</div>
                    <div className="btn-more"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="news-list-wrap">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="news-lists-items">
                  <div className="row">
                    {newslists.map((news) => (
                      <div className="col-12 col-md-6 col-xl-4 news-item">
                        <div className="news-lists">
                          <div className="news-title"> {news.newstitle}</div>
                          <div className="news-link-wrap">
                            <div className="news-link">{news.newsurlname}</div>
                            <a
                              href={news.newsurl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="news-link-icon"></div>
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
