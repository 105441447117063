import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="py-4 landing-footer">
      <div className="container px-4 px-xl-0">
        <div className="row flex-md-row-reverse align-items-center">
          <div className="col-md-6 d-flex justify-content-md-end">
            <Link className="mb-4 mb-md-0 link" to="/page/terms">
              <p className="revealme">{t("landing.footer.terms")}</p>
            </Link>
            <Link className="mb-4 mb-md-0 link" to="/page/privacy">
              <p className="revealme">{t("landing.footer.privacy")}</p>
            </Link>
            <Link className="mb-4 mb-md-0" to="/page/cookie">
              <p className="revealme">{t("landing.footer.cookie")}</p>
            </Link>
          </div>

          <div className="col-md-6 row flex-md-row-reverse m-0">
            <div className="col-md-6 p-0 ">
              <p>{t("landing.footer.address")}</p>
            </div>

            <div className="col-md-6 p-0">
              <p>{t("landing.footer.copyright")} </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
