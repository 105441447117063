import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

function QuestionBox({ changeHandler }) {
  const { t } = useTranslation();

  const questionChangeHandler = (event) => {
    changeHandler(event.target.value);
  };
  return (
    <>
      <div className="col-12 my-3 subheader">
        {t("quizzer.question.subtitle")}
      </div>
      <div className="col-12">
        <textarea
          rows="1"
          className="form-control text-center question-box"
          placeholder={t("quizzer.question.placeholder")}
          aria-label="Type your message here"
          aria-describedby="basic-addon2"
          onChange={questionChangeHandler}
        />
      </div>
    </>
  );
}

QuestionBox.propTypes = {
  changeHandler: PropTypes.func.isRequired
};

export default QuestionBox;
