import React from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie-player";
import lottieJson3 from "../Shapes/graphic-3.json";

function Overview() {
  const { t } = useTranslation();
  return (
    <section className="overview" id="overview">
      <div className="lottie-wrap">
        <div className="lottiefile">
          <Lottie
            className="lootie-animate animOne"
            loop
            animationData={lottieJson3}
            play
            style={{ width: "100%" }}
          />
        </div>
      </div>

      <div className="px-4 px-xl-0 container">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="section-title mb-5 text-center revealme">
              <span className="h2 d-block  fw-bold ">
                {t("landing.overview.title")}
              </span>
            </div>
          </div>

          <div className="col-md-10">
            <div className="section-content text-center">
              <span className="h5 d-block mb-4  revealme">
                {t("landing.overview.para_1")}
              </span>

              <p className="revealme">{t("landing.overview.para_2")}</p>

              <span className="highlight h6 mt-5 fc-white bg-green px-4 py-3 d-block revealme">
                {t("landing.overview.para_3")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Overview;
