import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import NoorBubble from "./NoorBubble";
import UserBubble from "./UserBubble";

function ChatContainer({ items, isLoading }) {
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest"
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [items]);

  return (
    <div id="chatcontainer">
      {items.map((bubble) =>
        bubble.sender === "noor-bot" ? (
          <NoorBubble
            text={bubble.text}
            isFirst={bubble.isFirst}
            key={bubble.id}
          />
        ) : (
          <UserBubble
            text={bubble.text}
            isFirst={bubble.isFirst}
            key={bubble.id}
          />
        )
      )}
      {isLoading && (
        <NoorBubble
          text={
            <div className="m-0">
              <div className="dot-falling" />
              <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
              </div>
            </div>
          }
          isFirst
        />
      )}
      <div ref={messagesEndRef} className="mt-3" />
    </div>
  );
}

ChatContainer.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      isFirst: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired
    })
  ).isRequired,
  isLoading: PropTypes.bool.isRequired
};
export default ChatContainer;
