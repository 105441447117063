import { Accordion, Collapse, Spinner } from "react-bootstrap";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { KeyPointEditor } from "./KeyPointEditor";
import { articleKeyPoints } from "../../../api/Api";
import "./Accordion.css";
import Reorder from "react-reorder";
import { ReactComponent as BarsIcon } from "../Icons/bars-solid.svg";
import { ArticleGeneratorContext } from "./ArticleGenerator";

export function KeyPoints({ onSectionsChange }) {
  const { t } = useTranslation();

  const { topic, title } = useContext(ArticleGeneratorContext);

  const [loading, setLoading] = useState(false);
  const [keyPoints, setKeyPoints] = useState([]);

  const [sections, setSections] = useState({});

  const onKeyPointEdit = useCallback((kp, value) => {
    setSections((prev) => {
      const next = { ...prev };
      next[kp] = value;
      return next;
    });
  }, []);

  const items = useMemo(() => {
    return keyPoints.map((kp) => {
      return (
        <Accordion.Item key={kp} eventKey={kp} className={`border-0`}>
          <Accordion.Header>
            <div className={"d-flex flex-grow-1 align-items-center gap-2"}>
              <BarsIcon width={"16px"} />
              {kp}
            </div>
          </Accordion.Header>
          <Accordion.Body
            className={"p-2"}
            onClick={(event) => event.stopPropagation()}
          >
            <KeyPointEditor
              keyPoint={kp}
              onEdit={onKeyPointEdit}
              // requires setting initial value so it does not get reset when reordering
              initialValue={sections[kp]}
            />
          </Accordion.Body>
        </Accordion.Item>
      );
    });
  }, [keyPoints, onKeyPointEdit, sections]);

  useEffect(() => {
    onSectionsChange(Object.values(sections).filter(Boolean));
  }, [onSectionsChange, sections]);

  useEffect(() => {
    setSections((prevSections) => {
      return keyPoints.reduce((newSections, curr) => {
        newSections[curr] = prevSections[curr];
        return newSections;
      }, {});
    });
  }, [keyPoints]);

  useEffect(() => {
    if (!title) {
      return;
    }

    setLoading(true);
    articleKeyPoints(topic, title, 1)
      .then(setKeyPoints)
      .finally(() => setLoading(false));
  }, [topic, title]);

  const content = loading ? (
    <div className={"d-flex justify-content-center"}>
      <Spinner animation={"border"} />
    </div>
  ) : (
    <Accordion>
      <Reorder
        holdTime={100}
        reorderId={"keypoints"}
        onReorder={(event, prevIndex, nextIndex) => {
          setKeyPoints((prev) => {
            const next = [...prev];
            const item = next[prevIndex];
            next.splice(prevIndex, 1);
            next.splice(nextIndex, 0, item);
            return next;
          });
        }}
      >
        {items}
      </Reorder>
    </Accordion>
  );

  return (
    <Collapse in={Boolean(title)}>
      <section>
        <h2 className={"m-2"}>{t("articleGen.keyPoints.title")}</h2>
        {content}
      </section>
    </Collapse>
  );
}
