import React, { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import List from "../List";

function Cookie() {
  const { t } = useTranslation();
  const cookies = t("cookie_policy.cookie", { returnObjects: true });

  useEffect(() => {
    const header = document.querySelector("header");
    const sticky = header.offsetTop;
    function stickyheader() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
    if (header) {
      window.onscroll = function onscroll() {
        stickyheader();
      };
    }
  }, []);
  return (
    <section className="policies">
      <div className="px-4 px-xl-0 mt-5 container">
        <div className="row justify-content-center">
          <div className="col-md-11 col-lg-12">
            <div className="section-title mb-5 text-center">
              <span className="h1 d-block  fw-bold revealme">
                {t("cookie_policy.title")}
              </span>
            </div>
          </div>
          <div className="col-md-11 col-lg-12">
            {cookies.map((cookie) => (
              <div className="mt-3">
                {cookie.subtext.map((sub) => (
                  <div>
                    <Trans key={cookie}>
                      <p>{sub.para}</p>
                    </Trans>
                    <List sub={sub} />
                    {sub.table && (
                      <table>
                        <thead>
                          <tr>
                            <td>{sub.table.title_1}</td>
                            <td>{sub.table.title_2}</td>
                            <td>{sub.table.title_3}</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{sub.table.row_1_1}</td>
                            <td>{sub.table.row_1_2}</td>
                            <td>{sub.table.row_1_2}</td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cookie;
