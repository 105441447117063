import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import "./Temperature.css";

export function Temperature({ onChange }) {
  const { t } = useTranslation();

  const [selected, setSelected] = useState(2);
  const buttons = useMemo(() => {
    return [
      {
        label: t("articleGen.options.temperature.low"),
        value: 0.3,
        color: "#E13D5E"
      },
      {
        label: t("articleGen.options.temperature.medium"),
        value: 0.6,
        color: "#F8C612"
      },
      {
        label: t("articleGen.options.temperature.high"),
        value: 0.9,
        color: "#00c08b"
      }
    ];
  }, [t]);

  useEffect(() => {
    onChange(buttons[selected].value);
  }, [selected, onChange, buttons]);

  return (
    <Col className={"d-flex flex-column gap-1 justify-content-start"}>
      <small className={"form-text"}>
        {t("articleGen.options.temperature.label")}
      </small>
      <div className={"d-flex gap-2"}>
        {buttons.map((data, i) => (
          <button
            key={data.label}
            className={`d-flex align-items-center justify-content-center btn temperature-button ${
              selected === i ? "--selected" : ""
            }`}
            style={{
              "--color": data.color
            }}
            onClick={(evt) => {
              evt.preventDefault();
              setSelected(i);
            }}
          >
            {data.label}
          </button>
        ))}
      </div>
    </Col>
  );
}
