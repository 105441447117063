import React from "react";
import { useTranslation } from "react-i18next";
import OptionInput from "./OptionInput";

const OptionsBox = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  const { ref1, ref2, ref3, ref4 } = ref;
  return (
    <>
      <div className="col-12 my-3 subheader">
        {t("quizzer.options.subtitle")}
      </div>
      <div className="d-flex justify-content-between flex-column flex-md-row">
        <OptionInput
          placeholder={t("quizzer.options.first_placeholder")}
          ref={ref1}
        />
        <OptionInput
          placeholder={t("quizzer.options.second_placeholder")}
          ref={ref2}
        />
      </div>
      <div className="d-flex justify-content-between flex-column flex-md-row">
        <OptionInput
          placeholder={t("quizzer.options.third_placeholder")}
          ref={ref3}
        />
        <OptionInput
          placeholder={t("quizzer.options.fourth_placeholder")}
          ref={ref4}
        />
      </div>
    </>
  );
});
export default OptionsBox;
