import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie-player";
import { Link } from "react-router-dom";
import lottieJson1 from "../Shapes/graphic.json";
import Typewriter from "../Typewriter";

function AfterBanner() {
  const { t } = useTranslation();

  const appsDropdown = useMemo(
    () => [
      { name: t("landing.afterbanner.summarizer"), path: "/summarize" },
      { name: t("landing.afterbanner.chatbot"), path: "/chatbot" },
      { name: t("landing.afterbanner.quizzer"), path: "/quizzer" },
      { name: t("landing.afterbanner.narrator"), path: "/narrator" }
    ],
    [t]
  );

  // eslint-disable-next-line no-unused-vars
  const dropdownMenu = useMemo(() => {
    const items = appsDropdown.map((item) => {
      return (
        <li key={item.path}>
          <Link className="dropdown-item" to={item.path}>
            {item.name}
          </Link>
        </li>
      );
    });
    return (
      <ul
        className="dropdown-menu dropdown-menu-dark text-center"
        aria-labelledby="dropdownMenuButton2"
      >
        {items}
      </ul>
    );
  }, [appsDropdown]);

  return (
    <section className="typrewriter" id="intro">
      <div className="container px-4 px-xl-0">
        <div className="row">
          <div className="col-md-12">
            <span className="h2 d-block fw-bold revealme ">
              {t("landing.afterbanner.title")}
            </span>

            <Typewriter
              text={t("landing.afterbanner.typewriter_text")}
              period={2000}
            />
          </div>

          <div className="col-md-12 d-flex justify-content-center mt-4">
            <div className="dropdown dropdown-typewriter revealme">
              <button
                className="btn btn-green dropdown-toggle"
                type="button"
                id="dropdownMenuButton2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t("landing.afterbanner.button")}
              </button>
              {/*{dropdownMenu}*/}
            </div>
          </div>
        </div>
      </div>

      <div className="lottie-wrap">
        <div className="lottiefile">
          <Lottie
            className="lootie-animate animOne"
            loop
            animationData={lottieJson1}
            play
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </section>
  );
}

export default AfterBanner;
