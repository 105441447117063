import { OptionsCarousel } from "./OptionsCarousel";
import { useCallback, useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { articleKeyPointArgument } from "../../../api/Api";
import { ArticleGeneratorContext } from "./ArticleGenerator";

export function KeyPointEditor({ keyPoint, onEdit, initialValue }) {
  const { topic, title } = useContext(ArticleGeneratorContext);
  const [value, setValue] = useState(initialValue);
  const { t } = useTranslation();
  const onGenerate = useCallback(
    (temperature) => {
      return articleKeyPointArgument(topic, title, keyPoint, temperature);
    },
    [topic, title, keyPoint]
  );

  useEffect(() => {
    onEdit(keyPoint, value);
  }, [keyPoint, onEdit, value]);

  return (
    <Form className={"d-flex flex-column gap-3 my-4"}>
      <OptionsCarousel onGenerate={onGenerate} onSelect={setValue} />
      <Form.Group>
        <Form.Text>{t("articleGen.keyPoints.tooltip")}</Form.Text>
        <Form.Control
          placeholder={t("articleGen.keyPoints.placeholder")}
          as={"textarea"}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          value={value}
          rows={8}
        />
      </Form.Group>
    </Form>
  );
}
