import React from "react";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

function HomeHeader({ langSwitch }) {
  const { t } = useTranslation();
  const navMenu = [];
  const switchClickHandler = () => {
    langSwitch();
  };
  const nav = [
    { title: t("landing.menu.overview"), path: "/#overview" },
    { title: t("landing.menu.whynoor"), path: "/#whynoor" },
    { title: t("landing.menu.thepurpose"), path: "/#purpose" },
    { title: t("landing.menu.thename"), path: "/#name" },
    { title: t("landing.menu.thenews"), path: "/page/news" }
  ];
  nav.forEach((item) => {
    navMenu.push(
      <li className="nav-item" key={item.path}>
        <HashLink className="nav-link" to={item.path}>
          {item.title}
        </HashLink>
      </li>
    );
  });
  return (
    <header className="landing-header" id="top">
      <nav className="navbar fixed-top navbar-expand-lg">
        <div className="container px-4 px-lg-0 ">
          <Link className="navbar-brand d-flex align-items-center" to="/">
            <img src="assets/images/logo_tii.svg" className="tii" alt="TII" />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <span className="navbar-toggler-icon material-icons-outlined">
              menu
            </span>
          </button>

          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <span className="offcanvas-title" id="offcanvasNavbarLabel">
                <img src="assets/images/noor-logo.svg" alt="" />
              </span>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <span className="material-icons-outlined">close</span>
              </button>
            </div>

            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                {navMenu}

                <li className="nav-item">
                  <button
                    type="button"
                    className="nav-link"
                    data-bs-dismiss="offcanvas"
                    onClick={switchClickHandler}
                  >
                    {t("landing.menu.switcher")}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
HomeHeader.propTypes = {
  langSwitch: PropTypes.func.isRequired
};
export default HomeHeader;
