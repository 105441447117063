import { Collapse, Dropdown, Form } from "react-bootstrap";
import { Button } from "../Common/Button";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ReactComponent as CompileIcon } from "../Icons/mug-hot-solid.svg";

export function Compilation({ sections }) {
  const [article, setArticle] = useState("");
  const { t } = useTranslation();

  return (
    <Form className={"d-flex flex-column gap-3 p-2"}>
      <Dropdown.Divider />
      <Form.Group className={"d-flex flex-column"}>
        <Form.Text>
          {`${sections.length} ${t("articleGen.compilation.selectedSections")}`}
        </Form.Text>
        <Button
          onClick={() => setArticle(sections.join("\n\n"))}
          icon={<CompileIcon width={"16px"} />}
        >
          {t("articleGen.compilation.compile")}
        </Button>
      </Form.Group>
      <Collapse in={Boolean(article)}>
        <Form.Group>
          <Form.Text>{t("articleGen.compilation.tooltip")}</Form.Text>
          <Form.Control
            placeholder={t("articleGen.compilation.placeholder")}
            as={"textarea"}
            onChange={(event) => setArticle(event.target.value)}
            value={article}
            rows={8}
          />
        </Form.Group>
      </Collapse>
    </Form>
  );
}
