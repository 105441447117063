import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as HeartIcon } from "../Icons/ic-heart.svg";
import Feedback from "../Feedback/Feedback";

function Footer({ appId, appName, isResetFeedback, setIsResetFeedback }) {
  const childProps = {
    appId,
    appName,
    isResetFeedback,
    setIsResetFeedback
  };

  return (
    <footer className="mt_auto">
      <Feedback {...childProps} />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="footer-content align-items-center justify-content-center flex-row"
              // dir="ltr"
            >
              <span>Made with </span>
              <span>
                <a className="App-link" href="/">
                  <HeartIcon />
                </a>
              </span>
              <span>At</span>
              <span>
                <a className="App-link" href="/">
                  <img alt="TII" src="./assets/images/logo_tii.svg" />
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  appId: PropTypes.string,
  appName: PropTypes.string,
  isResetFeedback: PropTypes.bool.isRequired,
  setIsResetFeedback: PropTypes.func.isRequired
};

export default Footer;
