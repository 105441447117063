import React from "react";
import PropTypes from "prop-types";

const OptionInput = React.forwardRef((props, ref) => (
  <div className="col-12 col-md-6 mb-1 p-1">
    <input
      type="text"
      className="form-control text-center option-box"
      placeholder={props.placeholder}
      aria-label="Type your message here"
      aria-describedby="basic-addon2"
      ref={ref}
    />
  </div>
));

OptionInput.propTypes = {
  placeholder: PropTypes.string.isRequired
};

export default OptionInput;
