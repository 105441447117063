import React, { useRef, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import PropTypes from "prop-types";

function Name({ lang }) {
  const { t } = useTranslation();
  const mask = useRef();
  const timeline = useRef();
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const qselector = gsap.utils.selector(mask);
    timeline.current = gsap
      .timeline({
        scrollTrigger: {
          trigger: mask.current,
          toggleActions: "restart none none reset"
        }
      })
      .set(mask.current, { autoAlpha: 1 })
      .from(qselector(".animated-logo"), 1.2, {
        xPercent: 100,
        scale: 1.5,
        ease: "power4.out"
      })

      .from(mask.current, 1.2, {
        xPercent: -100,
        scale: 1.5,
        delay: -1.3,
        ease: "power4.out"
      })

      .to(qselector(".animated-logo"), {
        visibility: "visible"
      });
    return () => {
      timeline.current.kill();
    };
  }, []);

  return (
    <section className="name" id="name">
      <div className="lottie-wrap">
        <div className="lottiefile">
          <lottie-player
            style={{ width: "100%" }}
            id="firstLottie"
            className="lootie-animate w-100"
            src="./Shapes/dot.json"
          />
        </div>
      </div>

      <div className="px-4 px-xl-0 container">
        <div className="row">
          <div className="col-md-5">
            <div className="section-title mb-5">
              <span className="h2 d-block fw-bold revealme">
                {t("landing.thename.title")}
              </span>
            </div>

            <div className="section-content">
              <span className="h2 d-block revealme">
                {t("landing.thename.noor")}
              </span>
              <div className="pronunciation mb-4 o-75 revealme">
                <span className="h6">
                  <Trans i18nKey="landing.thename.arabic" />
                </span>
                <span className="h6 ms-3">
                  <Trans i18nKey="landing.thename.pronounce" />
                </span>
              </div>

              <span className="h5 d-block mb-4 revealme">
                {t("landing.thename.para_1")}
              </span>

              <p className="revealme">{t("landing.thename.para_2")}</p>
              <p className="revealme">{t("landing.thename.para_3")}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="f-image h-100">
        <div className="container-fluid h-100 p-0">
          <div className="row m-0 h-100">
            <div
              className={`col-md-6 ${
                lang === "ar" ? "me-auto" : "ms-auto"
              } h-100 p-0`}
            >
              <div className="content-image bg-green w-100 h-100">
                <div className="mask h-100" ref={mask}>
                  <img
                    src="./assets/images/noor-logo-img.svg"
                    data-src="./assets/images/noor-logo-img.svg"
                    alt="noor-logo"
                    className="animated-logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
Name.propTypes = {
  lang: PropTypes.string.isRequired
};
export default Name;
