import React from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie-player";
import lottieJsonCircle from "../Shapes/circle.json";

function Purpose() {
  const { t } = useTranslation();
  return (
    <section className="bg-gray purpose" id="purpose">
      <div className="lottie-wrap">
        <div className="lottiefile">
          <Lottie
            className="lootie-animate animOne"
            loop
            animationData={lottieJsonCircle}
            play
            style={{ width: "100%" }}
          />
        </div>
      </div>

      <div className="px-4 px-xl-0 container">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="section-title mb-5 text-center fc-white revealme">
              <span className="h2 d-block fw-bold">
                {t("landing.thepurpose.title")}
              </span>
            </div>
          </div>

          <div className="col-md-10">
            <div className="section-content text-center">
              <span className="h5 d-block mb-4 fc-white revealme">
                {t("landing.thepurpose.para_1")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Purpose;
