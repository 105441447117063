import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

function AnswerBox({ isLoading, answer }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="col-12 my-3 subheader">
        {t("quizzer.answer.subtitle")}
      </div>
      <textarea
        className="col-12 my-3 p-3 answer-box"
        value={isLoading ? t("app.loading") : answer}
        rows="5"
        readOnly
      />
    </>
  );
}
AnswerBox.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  answer: PropTypes.string.isRequired
};
export default AnswerBox;
