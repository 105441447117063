import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as NoorAvatar } from "../Icons/noor-logo-green.svg";

function NoorBubble({ isFirst, text }) {
  return (
    <div className="noorbot">
      {isFirst && (
        <div className="img ms-1">
          <NoorAvatar />
        </div>
      )}
      <div className="bubble" style={!isFirst ? { marginRight: "32px" } : null}>
        {text}
      </div>
    </div>
  );
}

NoorBubble.propTypes = {
  isFirst: PropTypes.bool.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
export default NoorBubble;
