import React, { useMemo, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Summarize from "./Components/Playground/Summarize/Summarize";
import Chat from "./Components/Playground/Chat/Chat";
import Quizzer from "./Components/Playground/Quizzer/Quizzer";
import Home from "./Components/Home/Home";
import Narrator from "./Components/Playground/Narrator/Narrator";
import ArticleGenerator from "./Components/Playground/ArticleGenerator/ArticleGenerator";
import Pages from "./Components/Pages/Pages";
import Header from "./Components/Playground/Header/Header";
import Footer from "./Components/Playground/Footer/Footer";

function App() {
  const { i18n } = useTranslation();
  const [appId, setAppId] = useState();
  const [isResetFeedback, setIsResetFeedback] = useState(true);
  document.documentElement.lang = i18n.language;

  const apps = useMemo(
    () => [
      {
        name: "summarize",
        component: Summarize
      },
      {
        name: "narrator",
        component: Narrator
      },
      {
        name: "chatbot",
        component: Chat
      },
      {
        name: "quizzer",
        component: Quizzer
      },
      {
        name: "articleGenerator",
        component: ArticleGenerator
      }
    ],
    []
  );

  // eslint-disable-next-line no-unused-vars
  const playgroundRoutes = useMemo(() => {
    const appChildProps = {
      setAppId,
      setIsResetFeedback
    };

    const footerChildProps = {
      appId,
      isResetFeedback,
      setIsResetFeedback
    };
    return apps.map((appInfo) => {
      const element = (
        <>
          <Header />
          <appInfo.component {...appChildProps} />
          <Footer {...footerChildProps} />
        </>
      );
      return <Route key={appInfo.name} path={appInfo.name} element={element} />;
    });
  }, [appId, apps, isResetFeedback]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        {/*{playgroundRoutes}*/}
        <Route path="page/*" element={<Pages />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
